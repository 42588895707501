import { useContext, useEffect, useState } from "react";
import {
  AiOutlineEdit,
  AiOutlineFacebook,
  AiOutlineFileImage,
  AiOutlineMessage,
} from "react-icons/ai";
import { BsFacebook, BsInstagram, BsMessenger } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {
  activatesMetaCampaign,
  getLatestMetaCampaign,
} from "../../controllers/campaignController";
import { UserContext } from "../../layouts/dashboardLayout";
import Loader from "../../components/loader";
import toast from "react-hot-toast";

const SummaryPage = () => {
  const { user, fbProfile, pages } = useContext(UserContext);
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lauchingCampaign, setLauchingCampaign] = useState(false);
  useEffect(() => {
    loadCampaign();
  }, []);
  const loadCampaign = () => {
    getLatestMetaCampaign().then((response) => {
      setLoading(false);
      const data = response.data.body;
      console.log(data);
      if (data) {
        setCampaign(data);
      } else {
        navigate("/create-campaign/?tab=setup");
      }
    });
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      <div className="shadow">
        <div className="w-full py-3 flex justify-between items-center px-6 bg-gray-50">
          <h1 className="font-bold">Your Investment</h1>
        </div>
        <div className="p-8 flex justify-between items-end">
          <div className="">
            <h1 className="text-2xl">
              TZS {campaign.Package.price.toLocaleString()}
            </h1>
            <p className="text-gray-500 text-sm">{campaign.Package.name}</p>
            <p className="mt-4">
              Duration Subscription -{" "}
              {campaign.Package.price == 30000
                ? "24 hours"
                : campaign.Package.price == 50000
                ? "7 Days"
                : "30 Days"}
            </p>
          </div>
          <div className="text-end">
            <h1 className="font-semibold mb-2">Estimated</h1>
            <p>Impressions: {campaign.Package.impressions}</p>
            <p>SMS: {campaign.Package.sms}</p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 mt-8">
        <div className="shadow">
          <div className="w-full py-3 flex justify-between items-center px-6 bg-gray-50">
            <h1 className="font-bold">Your Details</h1>
          </div>

          <div className="px-6 py-6 text-sm">
            <p className="text-gray-500 text-sm">Business Name: {user.name}</p>
            <p className="text-gray-500 text-sm">
              Business Category: {user.BusinessCategory.name}
            </p>
            <p className="text-gray-500 text-sm">
              Facebook page: {pages[0].name}
            </p>
          </div>
        </div>
        <div className="shadow">
          <div className="w-full py-3 flex justify-between items-center px-6 bg-gray-50">
            <h1 className="font-bold">Your campaign target</h1>
            <div
              onClick={() => {
                navigate("/create-campaign/?tab=target");
              }}
            >
              <AiOutlineEdit />
            </div>
          </div>
          <div className="p-6">
            <p className="text-gray-500 text-sm">Location: {campaign.target}</p>
            <p className="text-gray-500 text-sm">
              Genders: {campaign.targetGender}
            </p>
            <p className="text-gray-500 text-sm">
              Ages: {campaign.targetMinAge} to {campaign.targetMaxAge} ages old
            </p>
            <p className="text-gray-500 text-sm">
              Interests:{" "}
              {Object.values(campaign.targetInterests).map((item) => {
                return <span>{item.name}, </span>;
              })}
            </p>
          </div>
        </div>
        <div className="shadow">
          <div className="w-full py-3 flex justify-between items-center px-6 bg-gray-50">
            <h1 className="font-bold">Your campaign channels</h1>
            <div
              onClick={() => {
                navigate("/create-campaign/?tab=target");
              }}
            >
              <AiOutlineEdit />
            </div>
          </div>
          <div className="p-6 text-sm text-gray-500">
            {Object.values(campaign.targetChannels).map((item) => {
              return <div>{item}</div>;
            })}
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-2">
        <button
          onClick={() => {
            navigate("/create-campaign?tab=budget");
          }}
          className="px-4 py-3 bg-gray-100 cursor-pointer  text-dark text-sm mt-12 font-semibold rounded-lg"
        >
          Previous
        </button>
        <button
          onClick={() => {
            setLauchingCampaign(true);
            activatesMetaCampaign(campaign.uuid)
              .then((response) => {
                toast.success(response.data.body.message);
                navigate("/");
                setLauchingCampaign(false);
              })
              .catch((err) => {
                console.log(err);
                setLauchingCampaign(false);
              });
            // navigate("/create-campaign?tab=summary");
          }}
          className="px-4 py-3 bg-primary w-40 flex justify-center items-center text-white text-sm mt-12 font-semibold rounded-lg"
        >
          {lauchingCampaign ? (
            <div className=" animate-spin size-6 border-4 rounded-full border-white  border-t-transparent" />
          ) : (
            "Lauch Campaign"
          )}
        </button>
      </div>
    </div>
  );
};

export default SummaryPage;
