const { getTokens } = require("../utils/authStore");
const { app } = require("./authController");

export const addMetaCampaign = async (data) => {
  return await app.post("/meta-campaigns/", data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const getMyMetaCampaigns = async (path) => {
  return await app.get(`/meta-campaigns/me/${path}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const getMetaCampaign = async (uuid) => {
  return await app.get(`/meta-campaigns/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const activatesMetaCampaign = async (uuid) => {
  return await app.post(`/meta-campaigns/activate/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const getLatestMetaCampaign = async () => {
  return await app.get(`/meta-campaigns/latest`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const editMetaCampaign = async (uuid, data) => {
  return await app.patch(`/meta-campaigns/${uuid}`, data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const deleteMetaCampaign = async (uuid) => {
  return await app.delete(`/meta-campaigns/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
