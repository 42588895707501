import { FaFacebook } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { getFacebookUrl } from "../../controllers/userController";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../layouts/dashboardLayout";
import TextForm from "../../components/textForm";
import SelectForm from "../../components/selectForm";
import { getPackages } from "../../controllers/packageController";
import { AiOutlineCheck } from "react-icons/ai";
import { useGetParams } from "../../utils/useGetParams";
import {
  addMetaCampaign,
  editMetaCampaign,
  getLatestMetaCampaign,
} from "../../controllers/campaignController";
import toast from "react-hot-toast";
import Loader from "../../components/loader";

const SetupPage = () => {
  const navigate = useNavigate();
  const { user, fbProfile, pages } = useContext(UserContext);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPackage, setSelectedPackage] = useState();
  const [campaign, setCampaign] = useState(null);
  useEffect(() => {
    getPackages().then((response) => {
      setPackages(response.data.body);
    });
  }, []);
  useEffect(() => {
    loadCampaign();
  }, []);
  const loadCampaign = () => {
    getLatestMetaCampaign().then((response) => {
      console.log(response.data.body);
      setCampaign(response.data.body);
      setLoading(false);
    });
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      {!campaign ||
        (campaign.Package == null && (
          <div className="fixed inset-0 z-50 bg-opacity-10 bg-black justify-center items-center flex">
            <div className="w-8/12 2xl:w-6/12 bg-white shaodw-lg rounded-lg p-10 ">
              <h1 className="font-semibold text-lg">Select Package</h1>
              <div className="grid grid-cols-3 gap-5 mt-4">
                {packages.map((item) => {
                  return (
                    <div className=" py-8 px-6 border border-gray-200 rounded-lg  ">
                      <p className="font-semibold text-dark text-3xl mb-1 ">
                        TZS {item.price.toLocaleString()}
                      </p>
                      <h1 className="text-sm text-gray-500">{item.name}</h1>
                      <p className="mt-3 font-medium">What you get</p>
                      <div className="flex space-x-2 text-gray-500 items-center">
                        <div className="size-5 flex justify-center bg-primary bg-opacity-10 rounded-full text-primary items-center">
                          <AiOutlineCheck />
                        </div>
                        <p>{item.impressions} impressions</p>
                      </div>
                      <div className="flex space-x-2 text-gray-500 items-center">
                        <div className="size-5 flex justify-center bg-primary bg-opacity-10 rounded-full text-primary items-center">
                          <AiOutlineCheck />
                        </div>
                        <p>{item.clicks} Clicks</p>
                      </div>
                      <div className="flex space-x-2 text-gray-500 items-center">
                        <div className="size-5 flex justify-center bg-primary bg-opacity-10 rounded-full text-primary items-center">
                          <AiOutlineCheck />
                        </div>
                        <p>{item.sms} SMS</p>
                      </div>
                      <div className="flex space-x-2 text-gray-500 items-center">
                        <div className="size-5 flex justify-center bg-primary bg-opacity-10 rounded-full text-primary items-center">
                          <AiOutlineCheck />
                        </div>
                        <p>0 MB</p>
                      </div>
                      <div className="flex space-x-2 text-gray-500 items-center">
                        <div className="size-5 flex justify-center bg-primary bg-opacity-10 rounded-full text-primary items-center">
                          <AiOutlineCheck />
                        </div>
                        <p>0 Minutes </p>
                      </div>
                      <button
                        onClick={() => {
                          setSelectedPackage(item);
                          const payload = {
                            packageId: item.id,
                          };
                          editMetaCampaign(campaign.uuid, payload).then(
                            (response) => {
                              toast.success("Selected successfully");
                              loadCampaign();
                            }
                          );
                        }}
                        className="py-3 bg-primary hover:bg-dark transition duration-200 w-full cursor-pointer text-white font-semibold rounded-lg mt-4"
                      >
                        Buy Package
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ))}

      <div>
        <h1 className="text-lg font-semibold">Basic informations</h1>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (pages.length > 0) {
            const payload = {
              name: e.target.campaign.value,
              link: e.target.link.value,
              page: pages[0],
            };
            editMetaCampaign(campaign.uuid, payload).then((res) => {
              toast.success("Updated successfully");
              navigate("/create-campaign?tab=target");
            });
          } else {
            toast.error("Connect your account first");
          }
        }}
      >
        <div className="flex space-x-16 mt-2">
          <div className=" grid grid-cols-2 w-full gap-x-24 gap-y-4 items-center">
            <TextForm
              label={"Business Name"}
              disabled={true}
              defaultValue={user.name}
              placeholder={"Enter your business name"}
            />
            <SelectForm
              disabled={true}
              options={[user.BusinessCategory.name]}
              defaultValue={user.BusinessCategory.name}
              label={"Business Category"}
            />

            {pages.length < 1 ? (
              <div className=" space-y-2 flex flex-col">
                <label className=" text-dark">Facebook Account</label>
                <button
                  onClick={() => {
                    getFacebookUrl().then((res) => {
                      const url = res.data.body.loginUrl;
                      window.location = url;
                    });
                  }}
                  className="px-4 py-4 flex items-center  justify-center space-x-2 bg-blue-500 hover:bg-opacity-90 transition-all duration-200 text-white text-sm  font-semibold rounded-lg"
                >
                  <span>
                    <FaFacebook className="text-lg" />
                  </span>
                  <p>Login with Facebook</p>
                </button>
              </div>
            ) : (
              <div className=" space-y-2 flex flex-col">
                <label className=" text-dark">Facebook Account</label>
                <div className="flex space-x-2 items-center">
                  <div>
                    <img
                      src={fbProfile.picture.data.url}
                      className="size-8 rounded-full"
                    />
                  </div>
                  <div>
                    <p className="font-semibold">{fbProfile.name}</p>
                    <p className="text-sm text-gray-500">{fbProfile.email}</p>
                  </div>
                </div>
              </div>
            )}
            <SelectForm
              required={false}
              values={pages.map((item) => item.accces_token)}
              options={pages.map((item) => item.name)}
              label={"Facebook Page"}
            />
            <TextForm
              label={"Campaign Name"}
              required
              defaultValue={campaign?.name}
              name="campaign"
              placeholder={"Enter your campaign name"}
            />
            <TextForm
              label={"Destination URL"}
              required
              defaultValue={campaign?.link}
              name="link"
              placeholder={"Enter destination url here"}
            />
            {campaign && campaign.Package && (
              <TextForm
                disabled={true}
                defaultValue={`${campaign.Package.name} (TZS ${campaign.Package.price})`}
                label={"Selected Package"}
              />
            )}
          </div>
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="px-4 py-3 bg-primary text-white text-sm mt-12 font-semibold rounded-lg"
          >
            Save & Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default SetupPage;
