const SelectForm = ({
  label,
  placeholder,
  name,
  defaultValue,
  options,
  required = true,
  disabled = false,
  values,
}) => {
  values = values || options;
  return (
    <div className="flex flex-col space-y-1 text-base  w-full">
      <label className="text-muted text-sm  dark:text-opacity-50 ">
        {label || ""}
      </label>
      <select
        required={required}
        disabled={disabled}
        name={name}
        defaultValue={defaultValue}
        className="form-style"
        placeholder={placeholder || ""}
      >
        {options.map((item, index) => {
          return <option value={values[index]}>{item}</option>;
        })}
      </select>
    </div>
  );
};

export default SelectForm;
