import { useContext, useEffect, useState } from "react";
import { AiOutlineExport } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { getMyMetaCampaigns } from "../controllers/campaignController";
import Pagination from "../components/pagination";
import { UserContext } from "../layouts/dashboardLayout";
import moment from "moment";
import Loader from "../components/loader";

const HomePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(6);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [count, setCount] = useState(0);
  const { user, fbProfile, pages } = useContext(UserContext);
  useEffect(() => {
    findData();
  }, [page, limit, keyword]);
  const findData = () => {
    getMyMetaCampaigns(`?limit=${limit}&page=${page}&keyword=${keyword}`).then(
      (response) => {
        const rows = response.data.body.rows;
        const count = response.data.body.count;
        console.log(rows);
        setData(rows);
        setCount(count);
        setLoading(false);
      }
    );
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <div className="">
        <div className="flex mb-4 justify-between items-center">
          <div>
            <h1 className="text-lg font-bold">My Campaigns</h1>
            <p className="text-sm text-gray-500">View your campaigns below</p>
          </div>
          <div className="flex space-x-4">
            <div className="">
              <input
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                placeholder="Search campaign here"
                className="form-style"
              />
            </div>
            <button
              onClick={() => {
                navigate(`/create-campaign?tab=setup`);
              }}
              className="bg-primary text-white font-bold cursor-pointer px-3 rounded-lg"
            >
              Create Campaign
            </button>
          </div>
        </div>
        <div className="bg-white p-4 shadow rounded-lg">
          <table className="w-full">
            <thead className="text-sm text-gray-500 ">
              <tr>
                <th className="text-start py-3 px-3">Campaign name</th>
                <th className="text-start py-3 px-3">Package type</th>
                <th className="text-start py-3 px-3">Activated At</th>
                <th className="text-start py-3 px-3">Campaign Status</th>
                <th className="text-start py-3 px-3">Reach</th>
                <th className="text-start py-3 px-3">Impressions</th>
                <th className="text-start py-3 px-3">Clicks</th>
                <th className="text-start py-3 px-3"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => {
                return (
                  <tr className=" even:bg-background even:bg-opacity-50">
                    <td className="text-sm text-start py-4 px-3">
                      {item.name}
                    </td>
                    <td className="text-sm text-start py-4 px-3">
                      {item.Package.name}
                    </td>
                    <td className="text-sm text-start py-4 px-3">
                      {moment(item.updatedAt).format("yyy,MMM DD hh:mm")}
                    </td>
                    <td className="text-sm text-start py-4 px-3">
                      <div>{item.isPublished ? "ACTIVE" : "PENDING"}</div>
                    </td>
                    <td className="text-sm text-start py-4 px-3">2083</td>
                    <td className="text-sm text-start py-4 px-3">4007</td>
                    <td className="text-sm text-start py-4 px-3">83</td>
                    <td className="text-sm text-start py-4 px-3">
                      <button
                        onClick={() => {
                          // alert("haldfjasdf");
                          navigate(
                            `/campaign-report?uuid=${item.uuid}&name=${item.name}`
                          );
                        }}
                        className="bg-primary bg-opacity-15 cursor-pointer text-primary font-bold py-2 px-4 hover:bg-opacity-100 hover:text-white transition-all duration-200 rounded-full text-sm"
                      >
                        View Stats
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination limit={limit} count={count} setPage={setPage} page={page} />
      </div>
    </div>
  );
};

export default HomePage;
