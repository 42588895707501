import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { range } from "../../utils/range";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {
  BsCloudLightning,
  BsGift,
  BsHouseExclamation,
  BsSafe,
  BsShop,
} from "react-icons/bs";
import { TiLocation } from "react-icons/ti";
import ModalRight from "../../components/modalRight";
import {
  editMetaCampaign,
  getLatestMetaCampaign,
} from "../../controllers/campaignController";
import Loader from "../../components/loader";
import toast from "react-hot-toast";
import { getAdIntrests } from "../../controllers/metaController";
import { UserContext } from "../../layouts/dashboardLayout";

const TargetPage = () => {
  const navigate = useNavigate();
  const { user, fbProfile, pages } = useContext(UserContext);
  const [selectedGender, setSelectedGender] = useState(0);
  const [minAge, setMinAge] = useState(16);
  const [maxAge, setMaxAge] = useState(19);
  const [radius, setRadius] = useState(1);
  const [showInterest, setShowInterest] = useState(false);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    loadCampaign();
  }, []);
  const loadCampaign = () => {
    getLatestMetaCampaign().then((response) => {
      setLoading(false);
      const data = response.data.body;
      console.log(data);
      setRadius(data.targetRadius);
      setSelectedGender(parseInt(data.targetGender) || 0);
      setMinAge(data.targetMinAge);
      setMaxAge(data.targetMaxAge);
      setSelectedChannels(Object.values(data.targetChannels || {}));
      setSelectedInterests(Object.values(data.targetInterests || {}));
      if (data) {
        setCampaign(data);
      } else {
        navigate("/create-campaign/?tab=setup");
      }
    });
  };

  const [interests, setInterests] = useState([]);
  const [keyword, setKeyword] = useState(user.BusinessCategory.name);
  const typedKeyword = "";
  useEffect(() => {
    findInterests();
  }, [keyword]);
  const findInterests = () => {
    getAdIntrests(keyword).then((response) => {
      setInterests(response.data.body);
    });
  };
  const toggleInterest = (interest) => {
    setSelectedInterests(
      (prev) =>
        prev.includes(interest)
          ? prev.filter((item) => item.id !== interest.id) // Remove if already selected
          : [...prev, interest] // Add if not selected
    );
  };

  const toggleChannel = (channel) => {
    setSelectedChannels(
      (prev) =>
        prev.includes(channel)
          ? prev.filter((item) => item !== channel) // Remove if already selected
          : [...prev, channel] // Add if not selected
    );
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <ModalRight
        showModal={showInterest}
        setShowModal={() => {
          setShowInterest(false);
        }}
        content={
          <div>
            <h1 className="text-lg mb-3 font-bold">Select interests</h1>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setKeyword(e.target.keyword.value);
              }}
              className="flex space-x-4"
            >
              <input
                className="form-style py-1"
                name="keyword"
                placeholder="Search interests here"
              />
              <button
                type="submit"
                className="py-1 bg-primary text-white px-5 rounded-lg"
              >
                Search
              </button>
            </form>
            <div className=" mt-3">
              {interests.map((item) => (
                <div
                  key={item.name}
                  className={`flex space-x-2 mt-2 mr-2 items-center px-4 text-sm py-1 rounded-full cursor-pointer `}
                >
                  <input
                    onClick={() => {
                      toggleInterest(item);
                    }}
                    checked={
                      selectedInterests.filter((e) => e.id == item.id).length >
                      0
                    }
                    className=" size-4"
                    type="checkbox"
                  />
                  <div>
                    <h1 className="font-medium text-sm">{item.name}</h1>
                    <p className="text-xs">ID:{item.id}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        }
      />

      <div className="flex space-x-6">
        <div className="w-8/12 h-[60vh] bg-gray-200">
          <div>
            <div>
              <iframe
                height=""
                className="w-full h-[60vh] rounded"
                src="https://www.google.com/maps/embed/v1/place?q=tanzania&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="w-4/12">
          <input
            placeholder="Search locations"
            className="py-2 px-4 rounded-lg text-sm border-gray-300 w-full focus:border-primary focus:ring-primary"
          />
          <div className="space-y-1 mt-3">
            <div className="flex space-x-1 items-center">
              <TiLocation />
              <label className="text-sm text-dark">
                Tanzania ({radius}km radius)
              </label>
            </div>
            <div className="flex space-x-2 mt-3 items-center">
              <label>1km</label>
              <Slider
                defaultValue={radius}
                onChange={(e) => {
                  setRadius(e);
                }}
                dotStyle={{
                  borderColor: "#6DBF48",
                  backgroundColor: "#6DBF48",
                  boxShadow: "#6DBF48",
                }}
                className="ring-0"
                trackStyle={{ backgroundColor: "#6DBF48", height: 5 }}
                railStyle={{ backgroundColor: "#ccc", height: 5 }}
                handleStyle={{
                  borderColor: "#6DBF48",
                  backgroundColor: "#6DBF48",
                  boxShadow: "none", // Removes any shadow
                  outline: "none", // Removes the focus ring
                  height: 20,
                  width: 20,
                  marginTop: -8,
                }}
              />

              <label>100km</label>
            </div>
          </div>
          <div className="space-y-1 mt-3">
            <label className="text-sm text-dark">Gender</label>
            <div className="flex space-x-2 mt-3">
              {["All", "Men", "Women"].map((item, index) => (
                <div
                  key={item}
                  onClick={() => setSelectedGender(index)}
                  className={`bg-gray-100 text-sm py-1 px-3 rounded-lg cursor-pointer ${
                    index === selectedGender ? "bg-primary text-white" : ""
                  }`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div className="space-y-1 mt-3">
            <label className="text-sm text-dark">Age</label>
            <div className="flex space-x-6">
              <select
                onChange={(e) => {
                  setMinAge(e.target.value);
                }}
                defaultValue={minAge}
                className="py-2 px-4 rounded-lg text-sm border-gray-300 w-full focus:border-primary focus:ring-primary"
              >
                {range(16, 65).map((e) => (
                  <option key={e} value={e}>
                    {e}
                  </option>
                ))}
              </select>
              <select
                defaultValue={maxAge}
                onChange={(e) => {
                  setMaxAge(e.target.value);
                }}
                className="py-2 px-4 rounded-lg text-sm border-gray-300 w-full focus:border-primary focus:ring-primary"
              >
                {range(19, 65).map((e) => (
                  <option key={e} value={e}>
                    {e}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="space-y-1 mt-3">
            <div className="flex justify-between">
              <label className="text-sm text-dark">Interests</label>
              <div
                onClick={() => setShowInterest(true)}
                className="cursor-pointer"
              >
                <AiOutlinePlus className="text-lg" />
              </div>
            </div>
            <div className="flex flex-wrap mt-3">
              {selectedInterests.map((interest) => (
                <div
                  key={interest.id}
                  className="bg-gray-100 flex items-center space-x-2  text-sm py-1 px-3 rounded-lg mr-2 mb-2"
                >
                  <p>{interest.name}</p>
                  <AiOutlineClose
                    className="text-xs cursor-pointer text-gray-600"
                    onClick={() => {
                      setSelectedInterests(
                        selectedInterests.filter((e) => e != interest)
                      );
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="space-y-1 mt-6">
            <div className="flex justify-between">
              <label className="text-sm text-dark">Ad channels</label>
            </div>
            <div className="flex flex-wrap  mt-3">
              {["Instagram", "Facebook"].map((item) => (
                <div
                  onClick={() => {
                    toggleChannel(item);
                  }}
                  key={item}
                  className={`${
                    selectedChannels.includes(item)
                      ? "bg-primary text-white"
                      : "text-dark bg-gray-100"
                  } text-sm mt-2 mr-2 py-1 px-3 cursor-pointer rounded-lg`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-2">
        <button
          onClick={() => navigate("/create-campaign?tab=setup")}
          className="px-4 py-3 bg-gray-100 cursor-pointer  text-dark  text-sm mt-12 font-semibold rounded-lg"
        >
          Previous
        </button>
        <button
          onClick={() => {
            if (selectedInterests.length > 0 && selectedChannels.length > 0) {
              const payload = {
                targetGender: selectedGender,
                targetRadius: radius,
                target: "TZ",
                targetMinAge: minAge,
                targetMaxAge: maxAge,
                targetInterests: selectedInterests.reduce(
                  (prev, item, index) => {
                    prev[index] = item;
                    return prev;
                  },
                  {}
                ),
                targetChannels: selectedChannels.reduce((prev, item, index) => {
                  prev[index] = item;
                  return prev;
                }, {}),
              };
              editMetaCampaign(campaign.uuid, payload).then((res) => {
                toast.success("Updated successfully");
              });
              console.log(payload);
              navigate("/create-campaign?tab=ads");
            } else {
              toast.error("Please select interests and channels to continue");
            }
          }}
          className="px-4 py-3 bg-primary text-white text-sm mt-12 font-semibold rounded-lg"
        >
          Save & Continue
        </button>
      </div>
    </div>
  );
};

export default TargetPage;
