import { getTokens } from "../utils/authStore";
import { app } from "./authController";

export const addBusinessCategories = async (data) => {
  return await app.post("/meta-campaigns/", data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getBusinessCategories = async () => {
  return await app.get(`/business-categories`);
};
