import { useNavigate } from "react-router-dom";

const StatsItem = ({ title, value, icon, path }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => {
        navigate(path);
      }}
      className="rounded-lg  bg-white p-5 shadow   flex items-center space-x-4 border border-slate-100 "
    >
      <div className="flex size-12 items-center justify-center text-2xl rounded-full bg-primary bg-opacity-10 text-primary">
        {icon}
      </div>

      <div className="flex items-start justify-center">
        <div className="text-start">
          <h4 className="text-lg font-bold text-black ">{value}</h4>
          <span className="text-sm text-muted">{title}</span>
        </div>
      </div>
    </button>
  );
};

export default StatsItem;
