import { useNavigate } from "react-router-dom";
import BarChart from "../../components/barChart";
import { getLatestMetaCampaign } from "../../controllers/campaignController";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../layouts/dashboardLayout";
import Loader from "../../components/loader";

const BudgetPage = () => {
  const navigate = useNavigate();
  const { user, fbProfile, pages } = useContext(UserContext);
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    loadCampaign();
  }, []);
  const loadCampaign = () => {
    getLatestMetaCampaign().then((response) => {
      setLoading(false);
      const data = response.data.body;
      console.log(data);
      if (data) {
        setCampaign(data);
      } else {
        navigate("/create-campaign/?tab=setup");
      }
    });
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      <div>
        <h1 className="text-lg font-semibold">
          Results based on Budget Selection
        </h1>
        <p className="text-gray-500">
          Budget: TZS {campaign.Package.price.toLocaleString()}
        </p>
      </div>
      <div className="w-9/12 2xl:w-6/12 mt-6">
        <BarChart
          data={[campaign.Package.impressions, campaign.Package.sms]}
          label={"count"}
          categories={["impressions", "SMS"]}
        />
      </div>
      <div className="flex justify-end space-x-2">
        <button
          onClick={() => {
            navigate("/create-campaign?tab=ads");
          }}
          className="px-4 py-3 bg-gray-100 cursor-pointer  text-dark text-sm mt-12 font-semibold rounded-lg"
        >
          Previous
        </button>
        <button
          onClick={() => {
            navigate("/create-campaign?tab=summary");
          }}
          className="px-4 py-3 bg-primary cursor-pointer text-white text-sm mt-12 font-semibold rounded-lg"
        >
          Save & Continue
        </button>
      </div>
    </div>
  );
};

export default BudgetPage;
