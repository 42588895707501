import { items } from "../utils/constants";
import { useGetParams } from "../utils/useGetParams";
import AdsPage from "./campaignCreationSteps/adsPage";
import BudgetPage from "./campaignCreationSteps/budgetpage";
import SetupPage from "./campaignCreationSteps/setupPage";
import SummaryPage from "./campaignCreationSteps/summaryPage";
import TargetPage from "./campaignCreationSteps/targetPage";

const CreateCampaignPage = () => {
  const params = useGetParams();

  let page = <div></div>;
  switch (params.tab) {
    case "setup":
      page = <SetupPage />;
      break;
    case "target":
      page = <TargetPage />;
      break;
    case "ads":
      page = <AdsPage />;
      break;
    case "budget":
      page = <BudgetPage />;
      break;
    case "summary":
      page = <SummaryPage />;
      break;
  }

  return (
    <div>
      <div className=" bg-gray-100 flex border-b border-gray-300 mb-4">
        {items(params).map((item, index) => {
          return (
            <div
              onClick={() => {
                // navigate(
                //   `/create-campaign/?tab=${item.title.toLowerCase()}`
                // );
              }}
              className={`${
                items(params).filter(
                  (e) => e.title.toLowerCase() == params.tab
                )[0].index >= index
                  ? "bg-primary text-white"
                  : "text-gray-500 "
              } py-3 pl-8 flex items-cente cursor-pointer ${
                items(params).filter(
                  (e) => e.title.toLowerCase() == params.tab
                )[0].index >= index
                  ? "border-r-4 border-white"
                  : "border-r-4 border-white"
              }`}
            >
              <div className="w-11/12">
                <h1 className="text-base 2xl:text-lg font-semibold">
                  {item.title}
                </h1>
                <p className="text-xs font-medium">{item.subtitle}</p>
              </div>
            </div>
          );
        })}
      </div>
      {page}
    </div>
  );
};

export default CreateCampaignPage;
