import { getTokens } from "../utils/authStore";
import { app } from "./authController";

export const getPackages = async (path) => {
  return await app.get(`/packages`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
