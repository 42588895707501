import { useState } from "react";
import { sendCode } from "../../controllers/authController";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import TextForm from "../../components/textForm";

const LoginPage = () => {
  const [Loading, setLoading] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="w-screen min-h-screen bg-background  flex justify-center items-center text-dark">
      <div className="w-10/12 md:w-4/12 2xl:w-3/12 rounded-xl bg-white flex">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const phone = e.target.phone.value;
            setLoading(true);
            const payload = {
              phone,
            };
            "".trim();
            sendCode(payload)
              .then((response) => {
                setLoading(false);
                toast.success("Sent successfully");
                navigate(`/confirm/${phone}`);
              })
              .catch((e) => {
                setLoading(false);
                console.log(e);
                const message = e.response.data.message;
                toast.error(message);
              });
          }}
          className=" w-full  py-24  flex flex-col items-center px-8 md:px-10 justify-center"
        >
          <img className="h-9" src="/Kwanza 1.svg" />

          <h1 className="font-semibold text-lg md:text-lg mb-1 mt-3">
            Login to continue
          </h1>
          <p className="text-sm text-gray-500 mb-5">
            Enter your credentials to login
          </p>
          <TextForm
            name={"phone"}
            placeholder={"Enter your phone number"}
            label={"Phone number"}
          />
          <button
            type="submit"
            className="bg-primary py-3 transition-all w-full font-semibold rounded-lg mt-4 text-white hover:bg-opacity-85  "
          >
            {Loading ? "Sending OTP..." : "Continue"}
          </button>
          <div className="flex space-x-1 text-sm mt-2">
            <p>Don't have an account ?</p>
            <p
              onClick={() => {
                navigate("/register");
              }}
              className="text-primary cursor-pointer font-semibold"
            >
              Register
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
