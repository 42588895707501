export const items = (params) => {
  return [
    {
      title: "Setup",
      index: 0,
      subtitle: "Includes contact details and social media accounts",
      isChecked: true,
      isActive: params.tab == "setup",
    },
    {
      title: "Target",
      index: 1,
      subtitle: "Location and demographics for your target audience",
      isChecked: false,
      isActive: params.tab == "target",
    },
    {
      title: "Ads",
      index: 2,
      subtitle: "Choose imagery that will appear in your campaigns",
      isChecked: false,
      isActive: params.tab == "ads",
    },
    {
      title: "Budget",
      index: 3,
      subtitle: "View projections based on your chosen budget",
      isChecked: false,
      isActive: params.tab == "budget",
    },
    {
      title: "Summary",
      index: 4,
      subtitle: "Review and confirm campaign settings",
      isChecked: false,
      isActive: params.tab == "summary",
    },
  ];
};
